import React, { useState, useEffect, useRef } from 'reactn'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import moment from 'moment'
import { ContextMenu } from 'primereact/contextmenu'

import { FC } from '../../Services'
import { Spinner, Button } from '../../Components'
import { getModifyExpenses, convertExpenses, deleteExpenses } from './EconomicTableAction'

function EconomicTable ({ history }) {
  // STATE
  const [expenses, setExpenses] = useState([])
  const [selectedExpenses, setSelectedExpenses] = useState([])
  const [selectedMenuExp, setSelectedMenuExp] = useState({})
  const [nPage, setNPage] = useState(0)
  const [limitMax, setLimitMax] = useState(1)
  const [loading, setLoading] = useState(true)
  const contextMenu = useRef(null)

  // USE EFFECT
  useEffect(() => {
    const getFirstTen = async () => {
      const limit = 100
      let ind = 0
      const callExpenses = async (ind) => {
        const dataRes = await FC.service('easyproject').find({
          query: { service: 'effective_expenses', limit, offset: ind * limit, paidFilter: '0' }
        })
        if (dataRes.length) {
          const { totalCount = 1 } = dataRes[0]
          const division = totalCount / limit || 0
          const dataCorrect = data.map((item) => {
            const dateDeadLine = moment(item.deadline).format('DD-MM-YYYY')
            item.deadline =
              dateDeadLine === 'Invalid date' ? (item.deadline = '') : (item.deadline = dateDeadLine)
            return {
              ...item
            }
          })
          setExpenses(dataCorrect)
          setLimitMax(division + 1)
          setLoading(false)
          setNPage(ind)
        } else {
          ind++
          callExpenses(ind)
        }
      }
      const data = await FC.service('easyproject').find({
        query: { service: 'effective_expenses', limit, page: 0, paidFilter: '0' }
      })
      if (!data) {
        return false
      }
      if (!data.length) {
        ind++
        callExpenses(ind)
        return false
      } else {
        const { totalCount = 1 } = data[0]
        const division = totalCount / limit || 0
        const dataCorrect = data.map((item) => {
          const dateDeadLine = moment(item.deadline).format('DD-MM-YYYY')
          item.deadline =
            dateDeadLine === 'Invalid date' ? (item.deadline = '') : (item.deadline = dateDeadLine)
          return {
            ...item
          }
        })
        setExpenses(dataCorrect)
        setLimitMax(division + 1)
        setLoading(false)
      }
    }

    getFirstTen().then((res) => setLoading(false))
  }, [])

  const contextMenuSettings = [
    {
      label: selectedExpenses.length > 1 ? 'Modifica selezionati' : 'Modifica',
      icon: 'pi pi-fw pi-pencil',
      command: (event) => getModifyExpenses(selectedExpenses, selectedMenuExp, history)
    },
    {
      label: selectedExpenses.length > 1 ? 'Segna come pagati' : 'Segna come pagato',
      icon: 'pi pi-money-bill',
      command: (event) => convertExpenses(selectedExpenses, selectedMenuExp)
    },
    {
      label: selectedExpenses.length > 1 ? 'Elimina Selezionati' : 'Elimina',
      icon: 'pi pi-fw pi-times',
      command: (event) => deleteExpenses(selectedExpenses, selectedMenuExp)
    }
  ]

  const genButtonRightPaginator = () => (
    <Button
      label='Pagina Successiva'
      onClick={async () => {
        const type = 'effective_expenses'
        const limit = 100
        const ind = nPage + 1

        if (nPage !== limitMax) {
          const response = await FC.service('easyproject').find({
            query: { service: type, limit, offset: ind * limit, paidFilter: '0' }
          })
          if (!response) return false
          setNPage(nPage + 1)
          setExpenses(response)
        }
      }}
    />
  )

  const genButtonLeftPaginator = () => (
    <Button
      label='Pagina Precedente'
      onClick={async () => {
        const type = 'effective_expenses'
        const limit = 100
        const ind = nPage - 1
        if (nPage) {
          const response = await FC.service('easyproject').find({
            query: { service: type, limit, offset: ind * limit, paidFilter: '0' }
          })
          if (!response) return false
          setNPage(nPage - 1)
          setExpenses(response)
        }
      }}
    />
  )

  const sortDate = (event) => {
    if (!expenses) return []
    const x = expenses.sort((e1, e2) => {
      const a =
        event.field === 'deadline'
          ? moment(e1.deadline, 'DD-MM-YYYY').toDate()
          : moment(e1.date, 'DD-MM-YYYY').toDate()
      const b =
        event.field === 'deadline'
          ? moment(e2.deadline, 'DD-MM-YYYY').toDate()
          : moment(e2.date, 'DD-MM-YYYY').toDate()
      const sum = moment(a).unix() - moment(b).unix()
      return sum * event.order
    })
    return x
  }

  const tableHeader = (
    <div style={{ height: 35, display: 'flex', justifyContent: 'center' }}>Spese Da Pagare</div>
  )

  if (loading) return <Spinner />
  return (
    <div style={{ width: '100%', height: '100%', padding: 40 }}>
      <ContextMenu model={contextMenuSettings} ref={contextMenu} />
      <DataTable
        rows={100}
        value={expenses}
        header={tableHeader}
        selection={selectedExpenses}
        contextMenuSelection={selectedMenuExp}
        onContextMenuSelectionChange={(e) => {
          setSelectedMenuExp(e.value)
        }}
        onContextMenu={(e) => contextMenu.current.show(e.originalEvent)}
        onSelectionChange={(e) => setSelectedExpenses(e.value)}
        emptyMessage='Scorri alla pagina seguente per visualizzare altri pagamenti'
      >
        <Column selectionMode='multiple' style={{ width: '45px' }} />
        <Column
          field='date'
          header='Data'
          sortable='custom'
          sortFunction={sortDate}
          filter
          filterMatchMode='contains'
        />
        <Column
          field='deadline'
          header='Scadenza'
          sortable='custom'
          sortFunction={sortDate}
          filter
          filterMatchMode='contains'
        />
        <Column field='supplier' header='Fornitore' sortable filter filterMatchMode='contains' />
        <Column field='import' header='Prezzo' sortable filter filterMatchMode='contains' />
        <Column field='importNoIva' header='Prezzo senza IVA' sortable filter filterMatchMode='contains' />
        <Column field='iva' header='Valore % IVA' sortable filter filterMatchMode='contains' />
        <Column field='description' header='Descrizione' sortable filter filterMatchMode='contains' />
        <Column field='project' header='Progetto' sortable filter filterMatchMode='contains' />
      </DataTable>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 25
        }}
      >
        {genButtonLeftPaginator()}
        <div>Pagina {nPage + 1}</div>
        {genButtonRightPaginator()}
      </div>
    </div>
  )
}

export default EconomicTable
