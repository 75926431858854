import { Sidebar } from 'primereact/sidebar'
import { useState } from 'reactn'
import React from 'react'

import { Text } from './Text'
import { logout, FC } from '../Services'
import { Spinner } from './Spinner'

const navbarElements = [
  {
    id: 'logout',
    label: 'Logout',
    action: () => {
      logout()
    },
    icon: 'pi pi-power-off'
  },
  {
    id: 'updateProject',
    label: 'Aggiorna Progetti',
    action: async () => {
      await FC.service('easyproject').find({ query: { service: 'saveProjectsOnDB' } })
    },
    icon: 'pi pi-refresh'
  }
]
/* '<i class="pi pi-power-off"></i>' */
function Navbar (props) {
  const [visibleSidebar, setVisibleSidebar] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div
        style={{
          width: '100%',
          height: 60,
          backgroundColor: 'rgb(52,125,209)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div>
          <i
            className='pi pi-bars'
            style={{
              border: 'none',
              fontSize: '2.8em',
              marginLeft: 'auto',
              color: 'white',
              cursor: 'pointer'
            }}
            onClick={() => setVisibleSidebar(true)}
          />
        </div>
        <div onClick={() => (window.location.href = '/')} style={{ marginLeft: 5, cursor: 'pointer' }}>
          <Text value='GESTIONALE' size={40} color='white' bold />
        </div>
      </div>
      <Sidebar visible={visibleSidebar} onHide={(e) => setVisibleSidebar(false)}>
        <div style={{ marginTop: 30 }}>
          {navbarElements.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                borderBottom: '1px solid #ddd'
              }}
              onClick={async () => {
                setLoading(true)
                await item.action()
                setLoading(false)
                window.growl.show({
                  severity: 'success',
                  summary: 'Conferma',
                  detail: 'Progetti aggiornati correttamente'
                })
              }}
            >
              {item.id === 'updateProject' && loading
                ? (
                  <Spinner />
                  )
                : (
                  <i
                    className={item.icon}
                    style={{
                      border: 'none',
                      fontSize: '2.8em',
                      cursor: 'pointer'
                    }}
                  />
                  )}
              <Text value={item.label} size={20} />
            </div>
          ))}
        </div>
      </Sidebar>
    </>
  )
}

export { Navbar }
