import PropTypes from 'prop-types'
import { Password } from 'primereact/password'
import { InputText } from 'primereact/inputtext'
import React from 'react'

function Input ({
  checkError = false,
  style,
  placeholder,
  id,
  value,
  disabled,
  onChange,
  isPassword = false,
  enterAction,
  readOnly = false,
  onClick,
  name,
  keyfilter = ''
}) {
  if (isPassword) {
    return (
      <Password
        style={ {
          ...styles.inputStyle,
          ...styles.boxShadow,
          ...style
        } }
        placeholder={ placeholder }
        id={ id }
        value={ value }
        disabled={ disabled }
        onChange={ onChange }
        feedback={ false }
        onKeyDown={ (key) => key.keyCode === 13 && enterAction() }
        name={ name }
      />
    )
  }
  return (
    <InputText
      name={ name }
      style={ {
        ...styles.inputStyle,
        ...styles.boxShadow,
        ...style,
        border: checkError ? '2px solid red' : 'none'
      } }
      placeholder={ placeholder }
      id={ id }
      value={ value }
      disabled={ disabled }
      onChange={ onChange }
      readOnly={ readOnly }
      onClick={ onClick }
      keyfilter={ keyfilter }
    />
  )
}

export { Input }

Input.propTypes = {
  style: PropTypes.object,
  placeholder: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isPassword: PropTypes.bool,
  enterAction: PropTypes.func
}

const styles = {
  inputStyle: {
    paddingLeft: 12,
    border: 'none',
    width: '100%',
    height: 40,
    borderRadius: 5
  },
  boxShadow: {
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  }
}
