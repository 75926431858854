import React, { useState, useEffect, useGlobal } from 'reactn'
import _ from 'lodash-uuid'
import moment from 'moment'

import styles from './style'
import Row from './Components/Row'
import { FC, sendJsonToEasyProject } from '../../Services'
import { Spinner, Card, Icon } from '../../Components'

const add = require('../../Resources/circularAdd.png')

const INS_CATEGORY = [
  { label: 'Spesa', value: 'expense' },
  { label: 'Entrata', value: 'revenue' }
]

const defaultState = {
  ins_category: INS_CATEGORY[0].value,
  ins_type: 'effective',
  deadline: null,
  fileName: null,
  paid: true,
  description: '',
  note: '',
  project: {
    id: '',
    idComm: '',
    path: ''
  },
  payment_type: '',
  bank: '',
  supplier: '',
  import: 0,
  importNoIva: 0,
  iva: 22,
  bgColor: '#007ad9',
  invoiceIcon: 'init', // require('../../Resources/addInvoice.png'),
  date: moment()
}

function Insert () {
  // State
  const [loading, setLoading] = useState(true)
  const [banks, setBanks] = useState([])
  const [paymentsType, setPaymentsType] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [completeRows, setCompleteRows] = useState([])
  const [errorRows, setErrorRows] = useState([])
  const [completeRowsIndex, setCompleteRowsIndex] = useState(0)
  const [errorRowsIndex, setErrorRowsIndex] = useState(0)
  const [activeRowType, setActiveRowType] = useState('error')
  const expensesToModify = useGlobal('expensesToModify')[0]

  useEffect(() => {
    const dataDownload = async () => {
      const results = await Promise.all([
        FC.service('easyproject').find({ query: { service: 'banks' } }),
        FC.service('easyproject').find({ query: { service: 'payments' } }),
        FC.service('easyproject').find({ query: { service: 'contacts' } })
      ])
      setBanks(results[0])
      setPaymentsType(results[1])
      setSuppliers(results[2])
      if (!expensesToModify) {
        const newRow = { ...defaultState, id: _.uuid() }
        setErrorRows([...errorRows, newRow])
      } else {
        const errors = []
        const corrects = []
        expensesToModify.forEach((exp) => {
          // Qui le spese hanno già un id.
          rowIsWrong(exp) ? errors.push(exp) : corrects.push(exp)
        })
        setErrorRows(errors)
        setCompleteRows(corrects)
        const activeType = errors.length ? 'error' : 'complete'
        setActiveRowType(activeType)
      }
    }

    dataDownload().then(() => {
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [expensesToModify])

  /* Funzioni di aggiornamento indici */
  const onCompletedButtonClick = () => {
    if (getActiveRow() !== undefined) checkActiveRowForSwitch()
    setActiveRowType('complete')
  }

  const onNotCompletedButtonClick = () => {
    if (getActiveRow() !== undefined) checkActiveRowForSwitch()
    setActiveRowType('error')
  }

  const onAddRowClick = (currentRow) => {
    currentRow && onChange(currentRow)
    const newRow = { ...defaultState, id: _.uuid() }
    setErrorRows((prev) => [...prev, newRow])
    // setErrorRows([...oldRows, newRow])
    setErrorRowsIndex(errorRows.length)
    if (activeRowType === 'complete') setActiveRowType('error')
  }

  const onDeleteRowClick = (currentRow) => {
    const activeRow = { ...getActiveRow() }
    // let newRows = []
    onChange(currentRow)
    if (activeRowType === 'error') {
      setErrorRows((prev) => prev.filter((ele) => ele.id !== activeRow.id))
      // newRows = [...oldRows].filter((elem) => elem.id !== activeRow.id)
      // setErrorRows(newRows)
      setErrorRowsIndex(0)
    } else {
      // const oldRows = onChange(currentRow)
      // newRows = [...oldRows].filter((elem) => elem.id !== activeRow.id)
      setCompleteRows((prev) => prev.filter((elem) => elem.id !== activeRow.id))
      setCompleteRowsIndex(0)
    }
  }

  const onNextArrowClick = (currentRow) => {
    onChange(currentRow)
    if (activeRowType === 'error') {
      if (errorRowsIndex !== errorRows.length - 1) {
        if (!checkActiveRowForSwitch()) {
          setErrorRowsIndex(errorRowsIndex + 1)
        }
      } else checkActiveRowForSwitch()
    } else {
      if (completeRowsIndex !== completeRows.length - 1) {
        if (!checkActiveRowForSwitch()) {
          setCompleteRowsIndex(completeRowsIndex + 1)
        }
      } else checkActiveRowForSwitch()
    }
  }

  const onPreveArrowClick = (currentRow) => {
    onChange(currentRow)
    if (activeRowType === 'error') {
      if (errorRowsIndex !== 0) {
        if (!checkActiveRowForSwitch()) {
          setErrorRowsIndex(errorRowsIndex - 1)
        }
      } else checkActiveRowForSwitch()
    } else {
      if (completeRowsIndex !== 0) {
        if (!checkActiveRowForSwitch()) {
          setCompleteRowsIndex(completeRowsIndex - 1)
        }
      } else checkActiveRowForSwitch()
    }
  }

  /* Funnzioni di supporto */
  const getActiveRow = () => {
    if (activeRowType === 'error') {
      return errorRows[errorRowsIndex]
    } else {
      return completeRows[completeRowsIndex]
    }
  }

  const checkActiveRowForSwitch = () => {
    const activeRow = { ...getActiveRow() }

    if (activeRowType === 'error') {
      if (!rowIsWrong(activeRow)) {
        setErrorRows((prev) => prev.filter((elem) => elem.id !== activeRow.id))
        setCompleteRows((prev) => [...prev, activeRow])
        setErrorRowsIndex(0)
        return true
      }
    } else {
      if (rowIsWrong(activeRow)) {
        setErrorRows((prev) => [...prev, activeRow])
        setCompleteRows((prev) => prev.filter((elem) => elem.id !== activeRow.id))
        setCompleteRowsIndex(0)
        return true
      }
    }
    return false
  }

  const refreshSuppliers = async () => {
    const updatedSupplier = await FC.service('easyproject').find({ query: { service: 'contacts' } })
    setSuppliers(updatedSupplier)
  }

  const onChange = (row) => {
    if (activeRowType === 'error') {
      setErrorRows((prev) => {
        prev[prev.findIndex((elem) => elem.id === row.id)] = row
        return prev
      })
    } else {
      setCompleteRows((prev) => {
        prev[prev.findIndex((elem) => elem.id === row.id)] = row
        return prev
      })
    }
  }

  const onSubmit = async (currentRow) => {
    let rows = []
    onChange(currentRow)
    if (activeRowType === 'error' && !rowIsWrong(currentRow)) rows = [...completeRows, currentRow]
    else if (completeRows.length === 0) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Nessun inserimento completo per l\'invio'
      })
      return false
    } else rows = [...completeRows]
    const res = await sendJsonToEasyProject(rows)
    // Caso errore
    if (res.filter((elem) => elem === undefined).length) {
      window.growl.show({
        severtiy: 'error',
        summary: 'Errore',
        detail: 'Attenzione l\'inserimento di alcune spese non è andato a buon fine'
      })
      return false
    }
    // Caso successo
    window.growl.show({
      severity: 'success',
      summary: 'Conferma',
      detail: 'Inserimenti andati a buon fine'
    })

    if (activeRowType === 'error' && !rowIsWrong(currentRow)) {
      setCompleteRows([])
      // const updatedErrorRows = errorRows.filter((elem) => elem.id !== currentRow.id)
      setErrorRows((prev) => prev.filter((elem) => elem.id !== currentRow.id))
      setCompleteRowsIndex(0)
      setErrorRowsIndex(0)
    } else {
      setCompleteRows([])
      setCompleteRowsIndex(0)
      setErrorRowsIndex(0)
      setActiveRowType('error')
    }
  }

  const isEmpty = () =>
    (activeRowType === 'complete' && completeRows.length === 0) ||
    (activeRowType === 'error' && errorRows.length === 0)

  const renderEmpty = () => (
    <Card style={styles.card}>
      <div
        id='btns'
        style={{
          display: 'flex',
          width: 'auto',
          flexDirection: 'column',
          marginLeft: 25,
          justifyContent: 'center',
          height: '20%'
        }}
      >
        <Icon
          src={add}
          alt='Aggiungi riga'
          onClick={() => {
            // onChange()

            onAddRowClick(getCurrentRow())
          }}
          style={{ marginRight: 30 }}
        />
        <p style={{ fontSize: 25 }}>Nessuna riga da visualizzare</p>
      </div>
    </Card>
  )

  const getCurrentRow = () =>
    activeRowType === 'error' ? errorRows[errorRowsIndex] : completeRows[completeRowsIndex]

  const currentRowIndex = () => (activeRowType === 'error' ? errorRowsIndex + 1 : completeRowsIndex + 1)

  const rowIsWrong = (row) => {
    const val =
      !row.description ||
      (row.description && row.description === '') ||
      !row.project ||
      row.project.id === '' ||
      !row.import ||
      (row.import && row.import === '') ||
      (!row.paid && !row.deadline) ||
      !row.supplier ||
      (row.supplier && row.supplier === '') ||
      !row.payment_type ||
      (row.payment_type && row.payment_type === '') ||
      !row.bank ||
      (row.bank && row.bank === '')
    return val
  }

  return loading
    ? (
      <div style={{ ...styles.mainBox, justifyContent: 'center' }}>
        <Spinner />
      </div>
      )
    : (
      <div id='mainBox' style={styles.mainBox}>
        <div id='btnsContainer' style={styles.btnsContainter}>
          <div
            id='completedButton'
            style={{
              ...styles.completedNotButton,
              borderBottomStyle: activeRowType === 'error' ? 'solid' : 'none'
            }}
            onClick={onCompletedButtonClick}
            cursor='pointer'
          >
            Completi {completeRows.length}
          </div>
          <div
            id='notCompletedButton'
            style={{
              ...styles.completedNotButton,
              borderBottomStyle: activeRowType === 'error' ? 'none' : 'solid'
            }}
            cursor='pointer'
            onClick={onNotCompletedButtonClick}
          >
            Incompleti {errorRows.length}
          </div>
        </div>
        <div>
          {
          isEmpty()
            ? renderEmpty()

            : (
              <Row
                row={getCurrentRow()}
                rowType={activeRowType}
                rowIndex={currentRowIndex()}
                onChange={onChange}
                suppliers={suppliers}
                paymentsType={paymentsType}
                banks={banks}
                nextArrow={onNextArrowClick}
                prevArrow={onPreveArrowClick}
                addRow={onAddRowClick}
                deleteRow={onDeleteRowClick}
                onSubmit={onSubmit}
                refreshSuppliers={refreshSuppliers}
              />
              )
}
        </div>
      </div>
      )
}

export { Insert }
