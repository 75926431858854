import React from 'react'

function Card ({ children, style }) {
  return (
    <div style={ {
      borderRadius: 20,
      boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.24)',
      height: 750,
      width: 680,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      ...style
    } }
    >
      { children }
    </div>
  )
}

export { Card }
