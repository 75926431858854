import { SlideMenu } from 'primereact/slidemenu'
import React, { useState, useRef, useEffect } from 'reactn'

import { Input, Spinner, Icon } from '../../../Components'
import { FC } from '../../../Services'
import commonStyles from '../style'

const refresh = require('../../../Resources/refresh.png')

function createProjectTree (sons, command) {
  const struct = []
  sons.forEach((elem) => {
    const item = {
      label: elem.name,
      path: elem.path,
      idComm: elem.idComm,
      command,
      idProg: elem._id
    }
    if (elem.children) {
      item.items = createProjectTree(elem.children, command)
    }
    struct.push(item)
  })
  return struct
}

function ProjectsDropdown ({ onChange, name, project, checkError }) {
  const [projectsSuggestion, setProjectsSuggestion] = useState([])
  const [path, setPath] = useState('')
  const [refreshing, setRefreshing] = useState(false)
  const setter = (e) => {
    const {
      item: { idProg, idComm, path }
    } = e
    const prog = {
      id: idProg,
      idComm,
      path
    }
    // Devo aggiornare lo stato tramite funcProps(prog)
    onChange({ target: { name, value: prog } })
    setPath(e.item.path)
  }

  useEffect(() => {
    const getProjectsSuggestion = async () => {
      const suggestion = await FC.service('easyproject').find({ query: { service: 'suggestionsProjects' } })
      const projects = createProjectTree(suggestion, setter)
      setProjectsSuggestion(projects)
    }

    getProjectsSuggestion()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (project.path !== path) setPath(project.path)
  }, [project.path, path])

  const refreshProjects = async () => {
    setRefreshing(true)
    await FC.service('easyproject').find({ query: { service: 'saveProjectsOnDB' } })
    const updatedSuggestions = await FC.service('easyproject').find({
      query: { service: 'suggestionsProjects' }
    })
    const projects = createProjectTree(updatedSuggestions, setter)
    setProjectsSuggestion(projects)
    setRefreshing(false)
  }

  const menu = useRef(null)

  return (
    <div style={styles.style}>
      <SlideMenu
        style={styles.menuStyle}
        backLabel='Indietro'
        menuWidth={300}
        viewportHeight={380}
        model={projectsSuggestion}
        ref={menu}
        popup
      />
      <Input
        readOnly
        placeholder='Progetto'
        value={path}
        onClick={(event) => {
          menu.current.toggle(event)
        }}
        checkError={checkError}
        style={{ ...styles.inputStyle }}
      />
      <div id='iconBox' style={commonStyles.iconBox}>
        {refreshing
          ? (
            <Spinner />
            )
          : (
            <Icon
              id='iconRefresh'
              src={refresh}
              alt='refresh'
              style={commonStyles.icon}
              onClick={refreshProjects}
            />
            )}
      </div>
    </div>
  )
}

export default ProjectsDropdown

const styles = {
  style: { display: 'flex', width: '100%' },
  menuStyle: { width: '300px', marginTop: '70px' },
  inputStyle: {
    width: '100%'
  },
  errorInputStyle: {
    width: '100%',
    border: '1px solid red',
    borderRadius: '3px'
  }
}
