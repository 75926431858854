import { AutoComplete } from 'primereact/autocomplete'
import { Editor } from 'primereact/editor'
import { OverlayPanel } from 'primereact/overlaypanel'
import { InputSwitch } from 'primereact/inputswitch'
import { Calendar } from 'primereact/calendar'
import { ListBox } from 'primereact/listbox'
import React, { useState, useRef, useEffect, useReducer } from 'reactn'
import utilityIva from 'utility-iva'

import { Card, Input, Icon, Spinner, Button } from '../../../Components'
import Header from './Header'
import ProjectsDropdown from './ProjectsDropdown'
import { Text } from '../../../Components/Text'
import styles from '../style'
import { uploadInvoice, FC } from '../../../Services'

const INS_CATEGORY = [
  { label: 'Spesa', value: 'expense' },
  { label: 'Entrata', value: 'revenue' }
]

const IT_STRUCT_CALENDAR = {
  firstDayOfWeek: 1,
  dayNames: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  monthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
}
const newNote = require('../../../Resources/newNote.png')
const refresh = require('../../../Resources/refresh.png')

function Row ({
  row,
  rowIndex,
  rowType,
  onChange,
  suppliers,
  banks,
  paymentsType,
  nextArrow,
  prevArrow,
  addRow,
  deleteRow,
  onSubmit,
  refreshSuppliers
}) {
  // STATE
  const [supplierSuggestion, setSupplierSuggestion] = useState([])
  const [refreshingSuppliers, setRefreshingSuppliers] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(rowIndex)
  const [currentType, setCurrentType] = useState(rowType)
  const [invoiceIcon, setInvoiceIcon] = useState(require('../../../Resources/addInvoice.png'))
  const [bgColor, setBgColor] = useState('#007ad9')

  const reducerFunc = (state, action) => {
    if (action.name === 'RESET') {
      return { ...row }
    }
    const { name, value } = action
    return { ...state, [name]: value }
  }
  const [currentRow, setCurrentRow] = useReducer(reducerFunc, { ...row })
  useEffect(() => {
    if (currentIndex !== rowIndex || currentType !== rowType || currentRow.id !== row.id || currentRow.invoiceIcon === 'init') {
      setCurrentRow({ name: 'RESET' })
      setCurrentIndex(rowIndex)
      setCurrentType(rowType)
      if (!currentRow.fileName) setInvoiceIcon(require('../../../Resources/addInvoice.png'))
      else setInvoiceIcon(require('../../../Resources/invoiceCorrect.png'))
      /* setCheckErrorSupplier(checkIfIsSupplier) */
      /*  setCheckErrorSupplier(false) */
    }
    // eslint-disable-next-line
  }, [rowIndex, currentIndex, rowType, currentType, row])

  const panel = useRef(null)
  const upload = useRef(null)

  const completeSupplier = (e) => {
    const suggestions = suppliers.filter((elem) => elem.toLowerCase().includes(e.query.toLowerCase()))
    suggestions.push('Aggiungi nuovo contatto ...')
    setSupplierSuggestion(suggestions)
  }

  const onRefreshingSuppliers = async () => {
    setRefreshingSuppliers(true)
    await refreshSuppliers()
    setRefreshingSuppliers(false)
  }

  const onChangeValue = (e) => {
    /* const { name, value } = e.target
     onChange({
      ...row,
      [name]: value
    }) */
    setCurrentRow(e.target)
  }
  const onChangeEditor = (e) => {
    const data = {
      name: 'note',
      value: e.htmlValue
    }
    setCurrentRow(data)
  }

  const onChangeSupplier = async (e) => {
    if (
      e.target.value === 'Aggiungi nuovo contatto ...' &&
      window.confirm(`Confermi di voler inserire il nuovo contatto: ${currentRow.supplier}`)
    ) {
      const supplierToAdd = currentRow.supplier
      setCurrentRow({ name: 'supplier', value: '' })
      const added = await FC.service('easyproject').create(
        { contact: supplierToAdd },
        { query: { service: 'newCustomer' } }
      )
      if (!added) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore',
          detail: 'Aggiunta non riuscita. Riprova'
        })
        return false
      }
      if (added.status === 200) {
        await refreshSuppliers()
        setCurrentRow({ name: 'supplier', value: supplierToAdd })
        window.growl.show({
          severity: 'success',
          summary: 'Conferma',
          detail: 'Contatto aggiunto'
        })
        return true
      }
      if (added.status === 501) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore',
          detail: 'Il contatto risulta già inserito'
        })
        return false
      }
    } else {
      setCurrentRow(e.target)
    }
  }

  const getImportNoIva = (value = '', iva = '') => {
    if (!value) return ''
    // const int
    return (Math.round((utilityIva.calcolaImponibile(parseFloat(value), parseFloat(iva)) + Number.EPSILON) * 100) / 100).toString()
  }

  const getImportWithIva = (value = '', iva = '') => {
    if (!value) return ''
    return (Math.round((utilityIva.calcolaImportoIvato(parseFloat(value), parseFloat(iva)) + Number.EPSILON) * 100) / 100).toString()
  }

  const onChangeImport = (e) => {
    const importNoIva = getImportNoIva(e.target.value, currentRow.iva)
    setCurrentRow({ name: 'import', value: e.target.value })
    setCurrentRow({ name: 'importNoIva', value: importNoIva })
  }

  const onChangeImportNoIva = (e) => {
    const importWithIva = getImportWithIva(e.target.value, currentRow.iva)
    setCurrentRow({ name: 'import', value: importWithIva })
    setCurrentRow({ name: 'importNoIva', value: e.target.value })
  }

  const onChangeIva = (e) => {
    const value = e?.target?.value || '0'
    const importWithIva = getImportWithIva(currentRow.importWithIva, value)
    const importNoIva = getImportNoIva(currentRow.import, value)
    if (importWithIva) setCurrentRow({ name: 'import', value: importWithIva })
    if (importNoIva) setCurrentRow({ name: 'importNoIva', value: importNoIva })
    setCurrentRow({ name: 'iva', value })
  }

  const uploadInv = async (e) => {
    const uploaded = await uploadInvoice(e.target.files[0])
    if (uploaded.error) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Upload non andato a buon fine.'
      })
      return false
    }
    setCurrentRow({ name: 'tokenFattura', value: uploaded.upload.token })
    setCurrentRow({ name: 'fileName', value: uploaded.fileName })
    setInvoiceIcon(require('../../../Resources/invoiceCorrect.png'))
  }

  const onClickInvoice = (e) => {
    if (currentRow.fileName === null) upload.current.click()
    else {
      setInvoiceIcon(require('../../../Resources/addInvoice.png'))
      setBgColor('#007ad9')
      setCurrentRow({ name: 'fileName', value: null })
    }
  }

  const onMouseEnterInvoice = (e) => {
    if (currentRow.fileName !== null) {
      setInvoiceIcon(require('../../../Resources/deleteInvoice.png'))
      setBgColor('#4e4b48')
    }
  }

  const onMouseLeaveInvoice = (e) => {
    if (currentRow.fileName !== null) {
      setInvoiceIcon(require('../../../Resources/invoiceCorrect.png'))
      setBgColor('#007ad9')
    }
  }

  return (
    <Card id='card' style={styles.card}>
      <Header
        index={rowIndex}
        addRow={addRow}
        deleteRow={deleteRow}
        prevRow={prevArrow}
        nextRow={nextArrow}
        currentRow={currentRow}
      />
      <div id='secondPart' style={styles.secondPart}>
        <div id='left' style={styles.left}>
          <div id='partOne' style={styles.partOne}>
            <div id='partOneBox' style={styles.partOneBox}>
              <ListBox
                options={INS_CATEGORY}
                name='ins_category'
                onChange={onChangeValue}
                value={currentRow.ins_category}
              />
              <div id='deadlineBox' style={styles.deadlineBox}>
                <div id='deadlineDiv' style={styles.deadlineDiv}>
                  Scadenza
                </div>
                <Calendar
                  id='calendarDeadline'
                  dateFormat='dd/mm/yy'
                  value={currentRow.deadline}
                  name='deadline'
                  onChange={onChangeValue}
                  style={styles.calendarDeadline}
                  inputStyle={{
                    width: '100%',
                    border: !currentRow.paid && !currentRow.deadline ? '2px solid red' : 'none'
                  }}
                />
              </div>
            </div>
            <div id='paid' style={styles.paid}>
              <div id='paidDiv' style={styles.paidDiv}>
                Pagato
              </div>
              <InputSwitch checked={currentRow.paid} name='paid' onChange={onChangeValue} />
            </div>
          </div>
          <div id='partTwo' style={styles.partTwo}>
            <div id='partTwoBox' style={styles.partTwoBox}>
              <div id='description' style={styles.description}>
                <div id='descriptionBox' style={styles.descriptionBox}>
                  <Input
                    placeholder='Descrizione'
                    onChange={onChangeValue}
                    name='description'
                    value={currentRow.description}
                    checkError={
                      !currentRow.description || (currentRow.description && currentRow.description === '')
                    }
                  />
                </div>
                <div id='iconBox' style={styles.iconBox}>
                  <Icon
                    id='iconNote'
                    src={newNote}
                    alt='newNote'
                    style={styles.icon}
                    onClick={(e) => panel.current.toggle(e)}
                  />
                </div>
                <OverlayPanel ref={panel} showCloseIcon dismissable>
                  <Editor
                    style={{ height: 300, width: 400 }}
                    value={currentRow.note}
                    name='note'
                    onTextChange={onChangeEditor}
                  />
                </OverlayPanel>
              </div>
              <div id='project' style={styles.project}>
                <div id='projectBox' style={styles.projectBox}>
                  <ProjectsDropdown
                    onChange={onChangeValue}
                    name='project'
                    project={currentRow.project}
                    checkError={!currentRow.project || currentRow.project.id === ''}
                  />
                </div>
              </div>
              <div id='supplier' style={styles.supplier}>
                <div id='supplierBox' style={styles.supplierBox}>
                  <AutoComplete
                    id='autocomplete'
                    style={{
                      ...styles.autocomplete,
                      border: suppliers.find((elem) => elem === currentRow.supplier) ? 'none' : '2px solid red'
                    }}
                    placeholder='Contatti'
                    inputStyle={{ width: '100%', border: 'none' }}
                    completeMethod={completeSupplier}
                    suggestions={supplierSuggestion}
                    name='supplier'
                    onChange={onChangeSupplier}
                    value={currentRow.supplier}
                  />
                </div>
                <div id='iconBox' style={styles.iconBox}>
                  {refreshingSuppliers
                    ? (
                      <Spinner />
                      )
                    : (
                      <Icon
                        id='iconRefresh'
                        src={refresh}
                        alt='refresh'
                        style={styles.icon}
                        onClick={onRefreshingSuppliers}
                      />
                      )}
                </div>
              </div>
            </div>
          </div>
          <div id='partThree' style={styles.partThree}>
            <div id='bankAndPayment' style={{ ...styles.bankAndPayment }}>
              <ListBox
                options={banks}
                name='bank'
                onChange={onChangeValue}
                value={currentRow.bank}
                listStyle={{
                  maxHeight: 150,
                  border:
                    !currentRow.bank || (currentRow.bank && currentRow.bank === '') ? '2px solid red' : 'none'
                }}
              />
              <ListBox
                options={paymentsType}
                name='payment_type'
                onChange={onChangeValue}
                value={currentRow.payment_type}
                listStyle={{
                  maxHeight: 150,
                  border:
                    !currentRow.payment_type || (currentRow.payment_type && currentRow.payment_type === '')
                      ? '2px solid red'
                      : 'none'
                }}
              />
            </div>
            <div style={{ ...styles.importAndInvoice }}>
              <div id='import' style={styles.import}>
                <Text value='Importo' style={{ marginBottom: 10, marginTop: -20 }} />
                <Input
                  id='importInput'
                  style={styles.importInput}
                  value={currentRow.import}
                  name='import'
                  onChange={onChangeImport}
                  checkError={!currentRow.import || (currentRow.import && currentRow.import === '')}
                  keyfilter='money'
                />
              </div>

              <div id='import' style={styles.import}>
                <Text value='Importo senza IVA' style={{ marginBottom: 10, marginTop: -20 }} />
                <Input
                  id='importNoIva'
                  style={styles.importInput}
                  value={currentRow.importNoIva}
                  name='importNoIva'
                  onChange={onChangeImportNoIva}
                  checkError={!currentRow.importNoIva || (currentRow.importNoIva && currentRow.importNoIva === '')}
                  keyfilter='money'
                />
              </div>
              <div id='import' style={styles.import}>
                <Text value='Valore % IVA' style={{ marginBottom: 10, marginTop: -20 }} />
                <Input
                  id='iva'
                  style={{ ...styles.importInput, width: '33%' }}
                  value={currentRow.iva}
                  name='iva'
                  onChange={onChangeIva}
                  checkError={!currentRow.iva || (currentRow.iva && currentRow.iva === '')}
                  keyfilter='money'
                />
              </div>

              <div style={{ display: 'flex', width: '50%', height: '100%' }}>
                <input
                  type='file'
                  accept='application/pdf'
                  ref={upload}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    uploadInv(e)
                    e.target.value = ''
                  }}
                />
                <Button
                  style={{ backgroundColor: bgColor, height: 100 }}
                  onClick={onClickInvoice}
                  onMouseEnter={onMouseEnterInvoice}
                  onMouseLeave={onMouseLeaveInvoice}
                >
                  <img
                    src={invoiceIcon}
                    alt='Fattura' width='40px' height='50px' title={currentRow.fileName} style={{ padding: '5px' }}
                  />
                </Button>

              </div>
            </div>
          </div>
        </div>
        <div id='right' style={styles.right}>
          <div id='date' style={styles.date}>
            <div id='dateBox' style={styles.dateBox}>
              Data
            </div>
            <div id='calendareDateBox' style={{ ...styles.calendarDateBox }}>
              <Calendar
                id='calendarDate'
                dateFormat='dd/mm/yy'
                inline
                value={currentRow.date}
                name='date'
                locale={IT_STRUCT_CALENDAR}
                onChange={onChangeValue}
                style={{ ...styles.calendarDate }}
              />
            </div>
          </div>
          <div id='sendBox' style={styles.sendBox}>
            <Button
              id='sendButton'
              label='Invia'
              onClick={() => onSubmit(currentRow)}
              style={styles.sendButton}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Row
