const styles = {
  mainBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 40
  },
  btnsContainter: {
    display: 'flex'
  },
  completedNotButton: {
    display: 'flex',
    height: 100,
    width: 250,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    fontSize: 25
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 850,
    borderTopLeftRadius: 0
  },
  secondPart: {
    display: 'flex',
    height: '80%',
    width: '100%'
  },
  left: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column'
  },
  partOne: {
    display: 'flex',
    width: '100%',
    height: '25%'
  },
  partOneBox: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  deadlineBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  deadlineDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 20
  },
  calendarDeadline: {
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },
  paid: {
    display: 'flex',
    width: '50%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 25
  },
  paidDiv: {
    fontSize: 20,
    marginRight: 20
  },
  partTwo: {
    display: 'flex',
    width: '100%',
    height: '50%'
  },
  partTwoBox: {
    display: 'flex',
    width: '100%',
    marginLeft: 25,
    marginRight: 25,
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  description: {
    display: 'flex'
  },
  descriptionBox: {
    display: 'flex',
    width: '95%',
    height: '100%'
  },
  iconBox: {
    display: 'flex',
    height: '100%',
    width: '5%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    padding: 2.5
  },
  icon: {
    backgroundColor: 'rgb(52,125,209)',
    height: '100%',
    width: '100%',
    padding: 5,
    borderRadius: 5
  },
  project: {
    display: 'flex'
  },
  projectBox: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  supplier: {
    display: 'flex'
  },
  supplierBox: {
    display: 'flex',
    width: '95%',
    height: '100%'
  },
  autocomplete: {
    display: 'flex',
    width: '100%',
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },
  partThree: {
    display: 'flex',
    width: '100%',
    height: '25%'
  },
  bankAndPayment: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  importAndInvoice: {
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  import: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 25
  },
  importInput: {
    width: '100%',
    height: 100
  },
  right: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  date: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  dateBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 20,
    fontSize: 20
  },
  calendarDateBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  calendarDate: {
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  },
  sendBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sendButton: {
    display: 'flex',
    width: 250,
    height: 100,
    backgroundColor: 'rgb(52,125,206)',
    color: '#ffffff',
    fontSize: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorStyle: {
    borderStyle: 'solid',
    borderColor: 'red'
  }
}

export default styles
