
import React from 'reactn'

import EconomicTable from './EconomicTable'
import { Card, Button } from '../../Components'
// import { uploadBankFile } from '../../Services'

const insertImg = require('../../Resources/insertCard.jpg')
// const excelImg = require('../../Resources/Excel.png')

function Home (props) {
  // const inputRef = useRef()

  /*   const uploadFile = async (e) => {
    const { fileJSON, error } = await uploadBankFile(e.target.files[0])
    if (error) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Errore nel caricamento del file. Riprova'
      })
      return false
    }
    await setGlobal({ expensesToModify: fileJSON })
    props.history.push('/Insert')
    // e.target.files[0]
  } */

  // const onClick = () => inputRef.current.click()

  // RENDER
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}>
          <div style={{ display: 'flex' }}>
            <Card style={{ width: 360, height: 335, marginRight: 15 }}>
              <div
                style={{
                  display: 'flex',
                  height: '60%',
                  borderBottomColor: 'black',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid'
                }}
              >
                <img src={insertImg} style={{ height: '100%', width: '100%' }} alt='insert' />
              </div>
              <div
                style={{
                  display: 'flex',
                  height: '40%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button label='Inserisci nuova operazione' onClick={() => props.history.push('/Insert')} />
              </div>
            </Card>
            {/* <Card style={ { width: 360, height: 335, marginLeft: 15 } }>
              <div
                style={ {
                  display: 'flex',
                  height: '60%',
                  borderBottomColor: 'black',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid'
                } }
              >
                <img src={ excelImg } style={ { height: '100%', width: '100%' } } alt='insert' />
              </div>
              <div
                style={ {
                  display: 'flex',
                  height: '40%',
                  justifyContent: 'center',
                  alignItems: 'center'
                } }
              >

                <input
                  type='file'
                  ref={ inputRef }
                  accept={ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
                  style={ { display: 'none' } }
                  onChange={ uploadFile }
                />
                <Button label='Carica file banche' onClick={ onClick } />
              </div>
            </Card> */}
            <Card style={{ width: 360, height: 335, marginRight: 15 }}>
              <div
                style={{
                  display: 'flex',
                  height: '60%',
                  borderBottomColor: 'black',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid'
                }}
              >
                <img src={insertImg} style={{ height: '100%', width: '100%' }} alt='insert' />
              </div>
              <div
                style={{
                  display: 'flex',
                  height: '40%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Button label='Inserisci singola spesa su più progetti' onClick={() => props.history.push('/InsertMulti')} />
              </div>
            </Card>
          </div>
        </div>
        <EconomicTable history={props.history} />
      </div>
    </div>
  )
}

export { Home }
