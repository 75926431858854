import { setGlobal, getGlobal } from 'reactn'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { LocalStorage, FC } from '.'

/* const STORAGE_KEY = ''
const DEFAULT_STATE = {
  effectiveExpenses: [],
  banks: [],
  paymentTypes: [],
  suppliers: [],
  projectsSuggestion: [],
  expensesToModify: []
} */

const downloadAllData = async () => {
  try {
    const results = await Promise.all([
      FC.service('easyproject').find({ query: { service: 'banks' } }),
      FC.service('easyproject').find({ query: { service: 'payments' } }),
      FC.service('easyproject').find({ query: { service: 'contacts' } })
    ])

    const {
      banks: oldBanks = [],
      paymentTypes: oldPaymentTypes = [],
      suppliers: oldSuppliers = []
    } = getGlobal()

    const banks = results[0] || oldBanks
    const paymentTypes = results[1] || oldPaymentTypes
    const suppliers = results[2] || oldSuppliers
    await setGlobal({ banks, paymentTypes, suppliers })
    return true
  } catch {
    return false
  }
}

const logout = (error) => {
  FC.logout()
  window.localStorage.clear()
  window.location.href = error ? '/errorPage' : '/'
}

const persistState = async (state) => {
  const keys = Object.keys(state)
  keys.forEach((key) => {
    LocalStorage.set(key, state[key])
  })
}

const isJWTValid = (jwt) => {
  try {
    return moment.unix(jwtDecode(jwt).exp) - moment() > 0
  } catch (e) {}
  return false
}

export { /* restoreState,  */ logout, isJWTValid, downloadAllData, persistState }
