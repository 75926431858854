const API_SERVERS = {
  production: 'https://gestionaleapi.kimera.info',
  local: 'http://localhost:3000'
}

const Environment = {
  API_BASE_URL: API_SERVERS.production
}

// ***** DEV-ONLY *****
const OVERRIDE_API_SERVER = false // Mettere FALSE in prod.
// const OVERRIDE_API_SERVER = API_SERVERS.local

OVERRIDE_API_SERVER && (Environment.API_BASE_URL = OVERRIDE_API_SERVER)

export { Environment }
