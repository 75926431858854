import { Environment } from './Environment'
import { LocalStorage } from './LocalStorage'

const feathers = require('@feathersjs/feathers')
const rest = require('@feathersjs/rest-client')
const axios = require('axios')
const fcAuth = require('@feathersjs/authentication-client')

const FC = {
  client: feathers()
}

FC.client.configure(
  rest(Environment.API_BASE_URL).axios(axios, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: LocalStorage.getJWT(),
      timeout: 60000
    }
  })
)

FC.client.configure(fcAuth({ storage: window.localStorage }))

FC.login = async (credentials) => {
  try {
    const res = await FC.client.authenticate(credentials)
    return res
  } catch (error) {
    FC.error = error
    console.log('Not authenticated')
    return false
  }
}

FC.logout = async () => {
  await FC.client.logout()
}

const doServiceMethod = async (serviceName, method, param1, param2) => {
  try {
    if (!param1 && !param2) return await FC.client.service(serviceName)[method]()
    if (!param2) return await FC.client.service(serviceName)[method](param1)
    return await FC.client.service(serviceName)[method](param1, param2)
  } catch (error) {
    // console.log(`Error calling: ${serviceName}. Method: ${method}param1: ${param1}param2: ${param2}`)
    return undefined
  }
}

FC.service = (serviceName) => ({
  find: (query) => doServiceMethod(serviceName, 'find', query),
  patch: (id, body) => doServiceMethod(serviceName, 'patch', id, body),
  get: (id, query) => doServiceMethod(serviceName, 'get', id, query),
  create: (body, query) => doServiceMethod(serviceName, 'create', body, query),
  remove: (id, query) => doServiceMethod(serviceName, 'remove', id, query)
})

export { FC }
