import React from 'react'
import { Button, Card, Text } from '../../../../Components'

export default function SelectedProject ({ project, onCancel }) {
  const macroProjects = project?.record?.map(({ name }) => name) || (project?.parentName && [project?.parentName]) || []
  return (

    <Card style={{ display: 'flex', flexDirection: 'column', maxHeight: 300, padding: 10, width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text value='Progetto selezionato' title bold />
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>

        <Text value='Nome progetto: ' bold />

        <Text style={{ marginLeft: 10 }} value={project.parentName} />
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <Text value='Sotto progetti selezionati: ' bold />
        <Text style={{ marginLeft: 10 }} value={macroProjects.join(', ')} />
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        <Button label='Ri-seleziona i progetti' onClick={onCancel} />
      </div>
    </Card>

  )
}
