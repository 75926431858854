import { setGlobal } from 'reactn'
import moment from 'moment'
import _ from 'lodash-uuid'

import { FC, sendJsonToEasyProject } from '../../Services'

// UNIQ nel caso in cui seleziono da tab e poi quello del tasto destro,
// se sono uguali, unisco, altrimenti aggiungo all'insieme degli elementi
export const getModifyExpenses = async (selectedExpenses, selectedMenuExp, history) => {
  const totalSelected = [...selectedExpenses, selectedMenuExp]
  const uniqueElements = _.uniq(totalSelected, true, (item) => item.id)
  const correctStruct = changeExpsShapeToInsertRow(uniqueElements, false)

  await setGlobal({ expensesToModify: correctStruct })

  // TODO da spostarsi su insertRow
  history.push('/Insert')
}

/* CONVERT EXPENSES */
export const convertExpenses = (selectedExpenses, selectedMenuExp) => {
  const totalSelected = [...selectedExpenses, selectedMenuExp]
  const uniqueElements = _.uniq(totalSelected, true, (item) => item.id)
  if (
    !window.confirm(
      `Confermi di volere CONVERTIRE l${uniqueElements.length > 1 ? 'e' : 'a'} spes${
        uniqueElements.length > 1 ? 'e' : 'a'
      } come PAGAT${uniqueElements.length > 1 ? 'E' : 'A'}?`
    )
  ) {
    return
  }
  const expsToDelete = changeExpsShapeToInsertRow(uniqueElements, false)
  const expsToAdd = changeExpsShapeToInsertRow(uniqueElements, true, { paid: '1' })
  convertExpensesFunc(expsToDelete, expsToAdd)
}
const convertExpensesFunc = async (expsToDelete, newExps) => {
  try {
    await sendJsonToEasyProject(newExps)
    await deleteExpensesFunc(expsToDelete)
  } catch (error) {}
}

/* DELETE EXPENSES */
export const deleteExpenses = (selectedExpenses, selectedMenuExp) => {
  const totalSelected = [...selectedExpenses, selectedMenuExp]
  const uniqueElements = _.uniq(totalSelected, true, (item) => item.id)
  if (
    !window.confirm(
      `Confermi di volere ELIMINARE l${uniqueElements.length > 1 ? 'e' : 'a'} spes${
        uniqueElements.length > 1 ? 'e' : 'a'
      } selezionat${uniqueElements.length > 1 ? 'e' : 'a'}? `
    )
  ) {
    return
  }
  const correctStruct = changeExpsShapeToInsertRow(uniqueElements, false)
  deleteExpensesFunc(correctStruct)
}

const deleteExpensesFunc = async (expenses) => {
  const promises = []
  const callDelete = async (elem) => {
    // TODO da capire cla chiamata cancellazione
    await FC.service('easyproject').remove(elem.id, {
      query: { service: 'effective_expenses' }
    })
  }
  expenses.forEach((elem) => {
    promises.push(callDelete(elem))
  })
  try {
    await Promise.all(promises)
    window.growl.show({
      severity: 'success',
      summary: 'Conferma',
      detail: 'Gli inserimenti sono stati modificati correttamente'
    })
    setTimeout(() => {
      window.location.reload()
    }, 500)
  } catch (err) {
    window.growl.show({
      severity: 'error',
      summary: 'Errore',
      detail: 'Errore nella modifica degli inserimenti, andare a controllare per non incorrere in duplicati'
    })
  }
}

/* GENERAL FUNCTION */

const changeExpsShapeToInsertRow = (expenses, newId, data) => {
  const type = ['effective', 'expenses']
  const dateTest = expenses.map((elem) => ({
    id: newId ? _.uuid() : elem.id,
    import: elem.import,
    description: elem.description,
    deadline: moment(elem.deadline, 'DD-MM-YYYY').toDate(),
    date: new Date(
      moment(elem.date, 'DD-MM-YYYY')
        .toDate()
        .setHours(
          moment(elem.date, 'DD-MM-YYYY')
            .toDate()
            .getHours() + 1
        )
    ),
    ins_type: type[0],
    ins_category: type[1].substring(0, type[1].length - 1),
    bank: elem.bank,
    supplier: elem.supplier,
    payment_type: elem.payment_type,
    paid: elem.paid === '1',
    project: {
      id: elem.idProg,
      idComm: elem.idComm,
      path: elem.project
    },
    fileName: null,
    errors: {},
    ...data
  }))

  return dateTest
}
