import React from 'react'

function Icon ({
  src,
  alt,
  width,
  height,
  onClick,
  style,
  title
}) {
  return (
    <img
      className='clickableIcon'
      src={src}
      alt={alt}
      width={width || 30}
      height={height || 30}
      onClick={onClick}
      style={{ cursor: 'pointer', ...style }}
      title={title || alt || ''}
    />
  )
}

export { Icon }
