import React, { useState } from 'react'
import { Button, Icon } from '../../../../Components'
import { Checkbox } from 'primereact/checkbox'

const previous = require('../../../../Resources/prev.png')
const next = require('../../../../Resources/next.png')

export function MenuContent ({ content = [], setContent, reset, onChange }) {
  const [multiSelected, setMultiSelected] = useState([])
  const [previousContent, setPreviousContent] = useState(content)

  const onClickMenuItem = (element) => () => {
    // Scateno l'onClicl solamente se ci sono dei figli. Se non ho figli vedo la selezione multipla e compare il bottone di conferma.
    if (element?.children) {
      setPreviousContent(content)
      setContent(element?.children)
      return null
    }
  }

  const Item = ({ element, index, isLast }) => {
    const showSelectAll = element?.multipleSelection && element?.parentId && index === 0

    const onChangeCheckbox = (e) => {
      if (e.value.name === 'Seleziona tutti') {
        if (e.checked) setMultiSelected([e.value, ...content])
        else setMultiSelected([])
        return
      }
      const selectedElements = [...multiSelected]
      if (e.checked) {
        selectedElements.push(e.value)
        setMultiSelected(selectedElements)
      } else {
        const filtered = selectedElements?.filter(val => val.id !== e.value.id)// selectedElements.splice(selectedElements.findIndex((obj) => obj.id === e.value.id))
        setMultiSelected(filtered)
      }
    }
    return (
      <div
        style={{
          height: 50, width: '100%', ...(isLast ? {} : { borderBottom: '1px solid black' }), display: 'flex', flexDirection: 'column', justifyContent: showSelectAll ? 'space-between' : 'center', cursor: element?.multipleSelection && element?.parentId ? null : 'pointer'
        }} onClick={onClickMenuItem(element)}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>

          {((element?.multipleSelection && element?.parentId) || element?.name === 'Seleziona tutti') && <Checkbox value={element} onChange={onChangeCheckbox} checked={!!multiSelected?.find((val) => val.id === element.id)} />}
          <span style={{ marginLeft: 10 }}>{element.name}</span>
        </div>
      </div>
    )
  }

  const onClickNext = () => {
    const reduced = multiSelected.filter((element) => element.id !== 0).reduce((prev, current) => {
      prev.parentName = current.parentName
      prev.record = [...(prev?.record || []), { name: current.name, id: current.id, idComm: current.idComm }]
      return prev
    }, {})
    onChange({ target: { name: 'project', value: reduced } })
  }

  const onClickBack = () => {
    if (!previousContent?.length || previousContent[0]?.id === content[0]?.id) reset()
    else setContent(previousContent)
    // Tutte le volte che clicco indietro azzero i progetti selezionati.
    setMultiSelected([])
  }

  const renderContent = () => {
    if (content?.every((element) => !element?.children?.length)) {
      const variousContent = [{ id: 0, name: 'Seleziona tutti' }, ...content]
      return variousContent.map((element, index) => <Item index={index} key={element.id} element={element} isLast={(index + 1) === variousContent.length} />)
    }
    return content?.map((element, index) => <Item index={index} key={element.id} element={element} isLast={(index + 1) === content.length} />)
  }

  return (
    <ul style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: 10, justifyContent: 'space-between', alignItems: 'center', height: 50, width: '100%' }}>
        <Icon src={previous} onClick={onClickBack} />
        {multiSelected?.length ? <Button label='Conferma' src={next} onClick={onClickNext} title='Conferma' alt='Conferma' /> : null}
      </div>
      {content?.length
        ? renderContent()
        : (
          <div style={{ height: 50, width: '100%', borderBottom: '1px solid black', display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span>Non sono stati trovati sottoprogetti comuni ai progetti precedentemente selezionati.</span>
            </div>
          </div>
          )}
    </ul>
  )
}
