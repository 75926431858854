import React from 'react'
import { Button as PrimeReactButton } from 'primereact/button'

function Button ({ disabled, label, icon, iconPosition, style, styleDisabled, onClick, children, onMouseLeave, onMouseEnter }) {
  if (children) {
    return (
      <PrimeReactButton
        disabled={ disabled }
        icon={ icon }
        className='no-text-button'
        iconPos={ iconPosition }
        style={ { ...styles.buttonStyle, ...style, ...styleDisabled, ...styles.boxShadow } }
        onClick={ onClick }
        onMouseEnter={ onMouseEnter }
        onMouseLeave={ onMouseLeave }
      >
        { children }
      </PrimeReactButton>
    )
  }
  return (
    <PrimeReactButton
      disabled={ disabled }
      label={ label }
      icon={ icon }
      className=''
      iconPos={ iconPosition }
      style={ { ...styles.buttonStyle, ...style, ...styleDisabled, ...styles.boxShadow } }
      onClick={ onClick }
    />
  )
}

export { Button }

const styles = {
  buttonStyle: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: 'none',
    color: 'black',
    borderRadius: 5,
    fontFamily: 'Lato, sans-serif',
    cursor: 'pointer',
    paddingLeft: 20,
    paddingRight: 20,
    height: 40
  },
  boxShadow: {
    boxShadow:
      '1px 1px 3px 0 rgba(0, 0, 0, 0.2), 1px 1px 1px 0 rgba(0, 0, 0, 0.14), 2px 1px 1px -1px rgba(0, 0, 0, 0.12)',
    borderRadius: 5
  }
}
