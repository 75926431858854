import PropTypes from 'prop-types'
import { Messages } from 'primereact/messages'
import React, { useState, useRef } from 'react'

import { Text } from './../../Components/Text'
import { FC } from '../../Services'
import { Input, Card, Button, Spinner } from '../../Components'

const accounting = require('../../Resources/accounting.jpg')

function Login (props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginSpinner, setLoginSpinner] = useState(false)

  const error = useRef(null)

  const showError = (summary) => {
    error.current.show({ severity: 'error', closable: false, summary })
  }

  const handleSubmit = async (e) => {
    setLoginSpinner(true)
    /* e.preventDefault() */
    const loginData = {
      strategy: 'local',
      email,
      password
    }
    const res = await FC.login(loginData)
    setLoginSpinner(false)
    if (!res) {
      showError('Email o password errati')
      return false
    }
    window.location.reload()
    // props.history.push('./')
  }

  return (
    <div style={ { display: 'flex', justifyContent: 'center' } }>
      <Card>
        <div
          style={ {
            display: 'flex',
            height: '60%',
            borderBottomColor: 'black',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid'
          } }
        >
          <img src={ accounting } style={ { height: '100%', width: '100%' } } alt={ 'accounting' } />
        </div>
        <div style={ { display: 'flex', height: '40%', justifyContent: 'center' } }>
          <div style={ { width: 450, height: '100%', padding: 40 } }>
            <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'center' } }>
              <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }>
                <div
                  style={ {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  } }
                >
                  <Text value='Login Gestionale' bold upcase size={ 30 } />
                </div>
                <Messages style={ { display: 'flex', height: 20, margin: '10px', color: 'red' } } ref={ error } />
                <Input
                  onChange={ (e) => setEmail(e.target.value) }
                  style={ { marginBottom: 15 } }
                  placeholder={ 'Email' }
                  error={ error }
                />
                <Input
                  onChange={ (e) => setPassword(e.target.value) }
                  style={ { marginBottom: 15 } }
                  placeholder={ 'Password' }
                  error={ error }
                  isPassword
                  enterAction={ handleSubmit }
                />
                <div style={ { display: 'flex', justifyContent: 'center' } }>
                  { loginSpinner ? <Spinner /> : <Button label={ 'Login' } onClick={ handleSubmit } /> }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export { Login }

Login.propTypes = {
  history: PropTypes.object
}
