import { Growl } from 'primereact/growl'
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
/* import Layout from 'pagelayoutmenu' */

import { isJWTValid/* , logout */ } from '../../Services'
/* import { Text } from '../../Components/Text' */
import { Navbar } from '../../Components'

import { Login, Home, Insert, InsertMulti } from '..'

function App () {
  /* const menuItems = [
    {
      label: 'Home',
      icon: (iconColor) => (
        <i
          className='pi pi-home'
          style={ {
            border: 'none',
            fontSize: '2.8em',
            cursor: 'pointer',
            color: iconColor
          } }
        />
      ),
      action: () => {
        window.location.href = '/'
      }
    },
    {
      label: 'Logout',
      icon: (iconColor) => (
        <i
          className='pi pi-power-off'
          style={ {
            border: 'none',
            fontSize: '2.8em',
            cursor: 'pointer',
            color: iconColor
          } }
        />
      ),
      action: () => {
        logout()
      }
    }
  ] */

  /* const iconTopBar = (
    <i
      className='pi pi-bars'
      style={ {
        border: 'none',
        fontSize: '2.8em',
        marginLeft: 'auto',
        color: 'white',
        cursor: 'pointer'
      } }
    ></i>
  ) */

  /*  const colors = {
    topMenu: {
      iconColor: 'black',
      textColor: 'black',
      backgroundColor: 'rgb(52, 125, 209)'
    },
    slidingMenu: {
      iconColor: 'black',
      textColor: 'black',
      backgroundColor: 'white',
      highlightColor: 'rgb(52, 125, 209)',
      highlightIconColor: 'white',
      highlightTextColor: 'white'
    }
  } */

  /*  const topBarContent = (
    <div style={ { display: 'flex', alignItems: 'center', width: '100%', height: '100%', marginLeft: 25 } }>
      <Text upCase value='GESTIONALE' size={ 40 } bold color='white' />
    </div>
  ) */
  const jwt = window.localStorage.getItem('feathers-jwt')
  const renderAuth = () => (
    /*     <>
      <Layout
        colors={ colors }
        menuItems={ menuItems }
        containerStyle={ {} }
        topBarMenuIcon={ iconTopBar }
        topBarContent={ topBarContent }
      > */
    <Switch>
      <Route exact path='/' component={ Home } />
      <Route path='/Insert' component={ Insert } />
      <Route path='/InsertMulti' component={ InsertMulti } />
    </Switch>
    /* </Layout>
    </> */
  )
  const renderNotAuth = () => (
    <Switch>
      <Route path='/' component={ Login } />
    </Switch>
  )

  return (
    <>
      <Navbar/>
      <div style={ { marginTop: 15 } }>
        <Growl
          ref={ (el) => {
            window.growl = el
          } }
          style={ { marginTop: 50 } }
        />
        <BrowserRouter>
          <div>{ jwt && isJWTValid(jwt) ? renderAuth() : renderNotAuth() }</div>
        </BrowserRouter>
      </div>
    </>
  )
}

export { App }
