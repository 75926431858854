import React, { useEffect, useState } from 'react'

import { Spinner, Text } from '../../../../Components'
import { FC } from '../../../../Services'
import { MenuContent } from './MenuContent'

// const projects = require('../Resources/names.json')

export default function ProjectsMultiSelection ({ onChange }) {
  const [content, setContent] = useState([])
  const [initialProjects, setInitalProjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getProjects = async () => {
      const suggestion = await FC.service('easyproject').find({ query: { service: 'suggestionsProjects' } })

      const maap = (elemnt) => {
        return {
          name: elemnt?.name,
          id: elemnt?._id,
          parentId: elemnt?.parent?.id,
          parentName: elemnt?.parent?.name,
          idComm: elemnt?.idComm,
          multipleSelection: !(elemnt?.children?.length), // !!elemnt?.children?.length, // elemnt?.children?.every(child => !child?.children?.length),
          children: elemnt?.children?.map(maap).sort((a, b) => {
            const number1 = a?.name?.split('-')[0].trim()
            const number2 = b?.name?.split('-')[0].trim()
            return Number(number1) - Number(number2)
          })
        }
      }
      const projects = suggestion?.map(maap)
      return projects
    }
    setLoading(true)
    getProjects().then((projects) => {
      setContent(projects)
      setInitalProjects(projects)
      setLoading(false)
    })
  }, [])

  const reset = () => setContent(initialProjects)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: 400, height: 'auto', overflow: 'auto', maxHeight: 550 }}>
      {
        loading
          ? (
            <div style={{ display: 'flex' }}>
              <Text value='Caricamento progetti in corso' />
              <Spinner size={20} />
            </div>
            )
          : (
            <div>
              <div style={{ marginBottom: 10 }}>
                <Text bold value='Seleziona un progetto' />
              </div>
              <MenuContent onChange={onChange} content={content} setContent={(e) => setContent(e)} reset={reset} />
            </div>
            )
      }
    </div>

  )
}
