import moment from 'moment'
import _ from 'lodash-uuid'

import { FC } from './FeathersClient'

const sendJsonToEasyProject = (data) => {
  const promises = []
  let elem
  const expensesToSend = []
  const expensesToDelete = []

  data.forEach((elem) => {
    if (!_.isUuid(elem.id)) expensesToDelete.push(elem)
    expensesToSend.push(elem)
  })

  expensesToSend.forEach((element) => {
    elem = convertJsonToEasyProjectFormat(element)
    promises.push(sendToEasyProject(elem, `${element.ins_type}_${element.ins_category}`))
  })

  expensesToDelete.forEach(async (elem) => {
    await FC.service('easyproject').remove(elem.id, { query: { service: 'effective_expenses' } })
  })
  return Promise.all(promises)
}

const getPaidValue = (val) => {
  if (val === undefined || val === null) return '0'
  if (typeof val !== 'boolean') return val
  if (val) return '1'
  return '0'
}

const convertJsonToEasyProjectFormat = (element) => {
  const paid = getPaidValue(element.paid)
  // TODO cazzo, assurdo
  // element.import && element.importNoIva && element.iva sono tutti String
  const price1 = parseFloat(element.import)
  const price2 = parseFloat(element.importNoIva)
  const vat = parseFloat(element.iva)
  const json = {
    easy_money: {
      entity_type: 'Project', // Prog
      entity_id: element.project.id, // idProgetto
      description: element.note ? element.note : '',
      price1, // Spesa con Iva.
      price2, // Spesa senza Iva
      easy_currency_code: 'EUR',
      vat,
      spent_on: moment(element.date).format('YYYY-MM-DD'), // Data della spesa
      name: element.description, // Descrizione della spesa
      custom_fields: [],
      tokenFattura: element.tokenFattura,
      invoiceName: element.fileName
    }
  }
  const type = `${element.ins_type}_${element.ins_category}`
  switch (type) {
    case 'effective_expense':
      json.easy_money.price1 = Math.abs(json.easy_money.price1)
      json.easy_money.price2 = Math.abs(json.easy_money.price2)
      json.easy_money.custom_fields = [
        { id: 38, value: [element.project.idComm] },
        { id: 60, value: element.supplier },
        { id: 41, value: element.payment_type },
        { id: 43, value: element.bank },
        { id: 61, value: element.deadline !== null ? moment(element.deadline).format('YYYY-MM-DD') : null },
        { id: 66, value: paid },
        { id: 78, value: vat }, // Valore % IVA
        { id: 74, value: json.easy_money.price2 } // Importo senza IVA
      ]
      json.easy_money.typeInvoice = 'easy_money_other_expenses'
      break

    case 'effective_revenue':
      json.easy_money.custom_fields = [
        { id: 54, value: [element.project.idComm] },
        { id: 65, value: element.supplier },
        { id: 62, value: element.bank },
        { id: 63, value: element.payment_type },
        { id: 64, value: element.deadline !== null ? moment(element.deadline).format('YYYY-MM-DD') : null },
        { id: 67, value: paid }, // campo paid DA RENDERE DINAMICO
        { id: 79, value: vat }, // Valore % IVA
        { id: 75, value: price2 } // Importo senza IVA
      ]
      json.easy_money.typeInvoice = 'easy_money_other_revenues'
      break

    default:
      throw new Error('bad String type')
  }
  return json
}

const attachInvoice = async function (tokenInvoice, idAssociate, typeInvoice, nameInvoice, type) {
  const jsonInvoice = {
    attach: {
      entity_type: typeInvoice,
      entity_id: idAssociate,
      attachments: [
        {
          token: tokenInvoice,
          filename: nameInvoice

        }
      ]
    }
  }
  await FC.service('easyproject').create(jsonInvoice, { query: { service: type } }) // Api.client.post(`/easyproject?service=${type}`, jsonInvoice)
}

const sendToEasyProject = async (json, type) => {
  let tokenInvoice, typeInvoice, nameInvoice
  if (json.easy_money) tokenInvoice = json.easy_money.tokenFattura
  if (json.easy_money) typeInvoice = json.easy_money.typeInvoice
  if (json.easy_money) nameInvoice = json.easy_money.invoiceName
  const response = await FC.service('easyproject').create(json, { query: { service: type } })

  if (tokenInvoice) {
    const idForInvoice = response[Object.keys(response)[0]].id
    attachInvoice(tokenInvoice, idForInvoice, typeInvoice, nameInvoice, 'attach')
  }
  return response
}

const uploadInvoice = async (file) => {
  const data = new FormData()
  data.append('file', file)
  const response = await FC.service('easyproject/uploadInvoice').create(data)
  if (!response) return { error: true }
  else {
    response.fileName = file.name
    return response
  }
}

const uploadBankFile = async (file) => {
  const data = new FormData()
  data.append('file', file)

  const response = await FC.service('easyproject/uploadExcel').create(data)

  if (!response) return { error: true }
  else return response
}

const readFile = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export { sendJsonToEasyProject, uploadInvoice, uploadBankFile, readFile }
