import React from 'react'

import { Icon } from '../../../Components'

const add = require('../../../Resources/circularAdd.png')
const remove = require('../../../Resources/circularClose.png')
const next = require('../../../Resources/next.png')
const prev = require('../../../Resources/prev.png')

export default function Header ({ index, addRow, deleteRow, prevRow, nextRow, currentRow }) {
  return (
    <div
      id='firstPart'
      style={ { display: 'flex', justifyContent: 'space-between', height: '20%', width: '100%' } }
    >
      <div
        id='btnAndTitle'
        style={ {
          display: 'flex',
          height: '100%',
          width: 'auto',
          marginLeft: 25,
          flexDirection: 'column',
          justifyContent: 'center'
        } }
      >
        <div id='btns' style={ { display: 'flex', width: 'auto', justifyContent: 'flex-start' } }>
          <Icon
            src={ add }
            alt={ 'Aggiungi riga' }
            onClick={ () => {
              // onChange()
              addRow(currentRow)
            } }
            style={ { marginRight: 30 } }
          />
          <Icon src={ remove } alt={ 'Rimuovi riga' } onClick={ () => deleteRow(currentRow) } />
        </div>
        <div style={ { fontSize: 45 } }>Inserimento { index }</div>
      </div>
      <div
        id='ArrowBtns'
        style={ { display: 'flex', height: '100%', width: 'auto', justifyContent: 'flex-end' } }
      >
        <div
          style={ {
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: 25,
            alignItems: 'center'
          } }
        >
          <Icon
            src={ prev }
            alt={ 'Precedente' }
            style={ { height: 75, width: 75, marginRight: 30 } }
            onClick={ () => prevRow(currentRow) }
          />
          <Icon src={ next } alt={ 'Successivo' } style={ { height: 75, width: 75 } } onClick={ () => nextRow(currentRow) } />
        </div>
      </div>
    </div>
  )
}
