import React, { useState, useRef, useEffect, useReducer } from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { Editor } from 'primereact/editor'
import { OverlayPanel } from 'primereact/overlaypanel'
import { InputSwitch } from 'primereact/inputswitch'
import { Calendar } from 'primereact/calendar'
import { ListBox } from 'primereact/listbox'
import utilityIva from 'utility-iva'
import moment from 'moment'

// import ProjectsMultiSelection from './Components/ProjectsMultiSelection'
import styles from './style'
import { Input, Icon, Spinner, Button, Text } from '../../Components'
import { FC, readFile } from '../../Services'
import { ProjectsMultiSelection, SelectedProject } from './Components/ProjectsMulti'

const INS_CATEGORY = [
  { label: 'Spesa', value: 'expense' },
  { label: 'Entrata', value: 'revenue' }
]

const IT_STRUCT_CALENDAR = {
  firstDayOfWeek: 1,
  dayNames: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
  monthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
}
const newNote = require('../../Resources/newNote.png')
const refresh = require('../../Resources/refresh.png')

const defaultState = {
  ins_category: INS_CATEGORY[0].value,
  ins_type: 'effective',
  deadline: null,
  fileName: null,
  paid: true,
  description: '',
  note: '',
  project: { name: '', id: null, parentId: null, parentName: null, idComm: null, record: [] },
  payment_type: '',
  bank: '',
  supplier: '',
  import: 0,
  importNoIva: 0,
  iva: 22,
  date: moment(),
  split: false,
  splittedImport: 0,
  splittedImportNoIva: 0,
  fileB64: ''

}

// const projects = [{ id: 5055, idComm: 5054 }, { id: 5055, idComm: 5054 }, { id: 5055, idComm: 5054 }, { id: 5055, idComm: 5054 }, { id: 5055, idComm: 5054 }]

// Fare la pagina per l'inserimento con il dividi spesa. Gestire il server per fare l'inserimento multiplo + uplaod multiplo.
function InsertMulti () {
  const reducerFunc = (state, action) => {
    if (action.name === 'RESET') {
      return { ...defaultState }
    }
    const { name, value } = action
    return { ...state, [name]: value }
  }
  const [currentRow, setCurrentRow] = useReducer(reducerFunc, { ...defaultState })
  const [banks, setBanks] = useState([])
  const [paymentsType, setPaymentsType] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [supplierSuggestion, setSupplierSuggestion] = useState([])
  const [refreshingSuppliers, setRefreshingSuppliers] = useState(false)
  const [invoiceIcon, setInvoiceIcon] = useState(require('../../Resources/addInvoice.png'))
  const [bgColor, setBgColor] = useState('#007ad9')
  const [loading, setLoading] = useState(false)

  const panel = useRef(null)
  const upload = useRef(null)

  useEffect(() => {
    const downloadData = async () => {
      const results = await Promise.all([
        FC.service('easyproject').find({ query: { service: 'banks' } }),
        FC.service('easyproject').find({ query: { service: 'payments' } }),
        FC.service('easyproject').find({ query: { service: 'contacts' } })
      ])
      setBanks(results[0])
      setPaymentsType(results[1])
      setSuppliers(results[2])
    }

    downloadData()
  }, [])

  const onCancelProject = () => setCurrentRow({ name: 'project', value: { name: '', id: null, parentId: null, parentName: null, idComm: null, record: [] } })

  const onChangeValue = (e) => {
    setCurrentRow(e.target)
  }

  const onChangeEditor = (e) => {
    const data = {
      name: 'note',
      value: e.htmlValue
    }
    setCurrentRow(data)
  }

  const completeSupplier = (e) => {
    const suggestions = suppliers.filter((elem) => elem.toLowerCase().includes(e.query.toLowerCase()))
    suggestions.push('Aggiungi nuovo contatto ...')
    setSupplierSuggestion(suggestions)
  }

  const refreshSuppliers = async () => {
    const updatedSupplier = await FC.service('easyproject').find({ query: { service: 'contacts' } })
    setSuppliers(updatedSupplier)
  }

  const onRefreshingSuppliers = async () => {
    setRefreshingSuppliers(true)
    await refreshSuppliers()
    setRefreshingSuppliers(false)
  }

  const onChangeSupplier = async (e) => {
    if (
      e.target.value === 'Aggiungi nuovo contatto ...' &&
      window.confirm(`Confermi di voler inserire il nuovo contatto: ${currentRow.supplier}`)
    ) {
      const supplierToAdd = currentRow.supplier
      setCurrentRow({ name: 'supplier', value: '' })
      const added = await FC.service('easyproject').create(
        { contact: supplierToAdd },
        { query: { service: 'newCustomer' } }
      )
      if (!added) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore',
          detail: 'Aggiunta non riuscita. Riprova'
        })
        return false
      }
      if (added.status === 200) {
        await refreshSuppliers()
        setCurrentRow({ name: 'supplier', value: supplierToAdd })
        window.growl.show({
          severity: 'success',
          summary: 'Conferma',
          detail: 'Contatto aggiunto'
        })
        return true
      }
      if (added.status === 501) {
        window.growl.show({
          severity: 'error',
          summary: 'Errore',
          detail: 'Il contatto risulta già inserito'
        })
        return false
      }
    } else {
      setCurrentRow(e.target)
    }
  }

  const getImportNoIva = (value = '', iva = '') => {
    if (!value) return ''
    // const int
    return (Math.round((utilityIva.calcolaImponibile(parseFloat(value), parseFloat(iva)) + Number.EPSILON) * 100) / 100).toString()
  }

  const getImportWithIva = (value = '', iva = '') => {
    if (!value) return ''
    return (Math.round((utilityIva.calcolaImportoIvato(parseFloat(value), parseFloat(iva)) + Number.EPSILON) * 100) / 100).toString()
  }

  const onChangeImport = (e) => {
    const importNoIva = getImportNoIva(e.target.value, currentRow.iva)
    setCurrentRow({ name: 'import', value: e.target.value })
    setCurrentRow({ name: 'importNoIva', value: importNoIva })
  }

  const onChangeImportNoIva = (e) => {
    const importWithIva = getImportWithIva(e.target.value, currentRow.iva)
    setCurrentRow({ name: 'import', value: importWithIva })
    setCurrentRow({ name: 'importNoIva', value: e.target.value })
  }

  const onChangeIva = (e) => {
    const value = e?.target?.value || '0'
    const importWithIva = getImportWithIva(currentRow.importWithIva, value)
    const importNoIva = getImportNoIva(currentRow.import, value)
    if (importWithIva) setCurrentRow({ name: 'import', value: importWithIva })
    if (importNoIva) setCurrentRow({ name: 'importNoIva', value: importNoIva })
    setCurrentRow({ name: 'iva', value })
  }

  const uploadInv = async (e) => {
    const file = e.target.files[0]
    const filename = file.name
    const base64 = await readFile(e.target.files[0])
    setCurrentRow({ name: 'fileB64', value: base64 })
    setCurrentRow({ name: 'fileName', value: filename })
    setInvoiceIcon(require('../../Resources/invoiceCorrect.png'))
  }

  const onClickInvoice = (e) => {
    if (currentRow.fileName === null) upload.current.click()
    else {
      setInvoiceIcon(require('../../Resources/addInvoice.png'))
      setBgColor('#007ad9')
      setCurrentRow({ name: 'fileName', value: null })
    }
  }

  const onMouseEnterInvoice = (e) => {
    if (currentRow.fileName !== null) {
      setInvoiceIcon(require('../../Resources/deleteInvoice.png'))
      setBgColor('#4e4b48')
    }
  }

  const onMouseLeaveInvoice = (e) => {
    if (currentRow.fileName !== null) {
      setInvoiceIcon(require('../../Resources/invoiceCorrect.png'))
      setBgColor('#007ad9')
    }
  }

  const onChangeSplitValue = (e) => {
    setCurrentRow(e.target)
    if (e.target.value) {
      const splittedImport = Math.round(100 * ((currentRow?.import || 0) / currentRow?.project?.record?.length || 1)) / 100 // (currentRow?.project?.record?.length || 1)
      setCurrentRow({ name: 'splittedImport', value: splittedImport })
      const splittedImportNoIva = getImportNoIva(splittedImport, currentRow.iva)
      setCurrentRow({ name: 'splittedImportNoIva', value: splittedImportNoIva })
    }
  }

  const rowIsWrong = (row) => {
    const val =
      !row.description ||
      (row.description && row.description === '') ||
      !row.project ||
      row.project.name === '' ||
      !row.import ||
      (row.import && row.import === '') ||
      (!row.paid && !row.deadline) ||
      !row.supplier ||
      (row.supplier && row.supplier === '') ||
      !row.payment_type ||
      (row.payment_type && row.payment_type === '') ||
      !row.bank ||
      (row.bank && row.bank === '')
    return val
  }

  const onSubmit = async () => {
    setLoading(true)
    const data = { ...currentRow }
    if (rowIsWrong(data)) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Dati obbligatori mancanti'
      })
      setLoading(false)
      return false
    }
    data.projects = data?.project?.record?.map((el) => ({ id: el.id, idComm: el.idComm })) || [{ id: data?.project?.id, idComm: data?.project?.idComm }]
    const response = await FC.service('easyproject').create(data, { query: { service: 'multipleUpload' } })

    if (!response) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Inserimento fallito. Riprova'
      })
      setLoading(false)
      return false
    }
    setLoading(false)
    window.growl.show({
      severity: 'success',
      summary: 'Attenzione',
      detail: 'Inserimento andato a buon fine.'
    })

    setTimeout(() => window.location.reload(), 2000)
  }

  return (

    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 50 }}>

      <div style={{ display: 'flex' }}>
        {currentRow?.project?.parentName
          ? <SelectedProject project={currentRow?.project} onCancel={onCancelProject} />
          : <ProjectsMultiSelection onChange={onChangeValue} />}
      </div>
      <div id='secondPart' style={{ ...styles.secondPart }}>
        <div id='left' style={styles.left}>
          <div id='partOne' style={styles.partOne}>
            <div id='partOneBox' style={styles.partOneBox}>
              <ListBox
                options={INS_CATEGORY}
                name='ins_category'
                onChange={onChangeValue}
                value={currentRow.ins_category}
              />
              <div id='deadlineBox' style={styles.deadlineBox}>
                <div id='deadlineDiv' style={styles.deadlineDiv}>
                  Scadenza
                </div>
                <Calendar
                  id='calendarDeadline'
                  dateFormat='dd/mm/yy'
                  value={currentRow.deadline}
                  name='deadline'
                  onChange={onChangeValue}
                  style={styles.calendarDeadline}
                  inputStyle={{
                    width: '100%',
                    border: !currentRow.paid && !currentRow.deadline ? '2px solid red' : 'none'
                  }}
                />
              </div>
            </div>
            <div id='paid' style={styles.paid}>
              <div id='paidDiv' style={styles.paidDiv}>
                Pagato
              </div>
              <InputSwitch checked={currentRow.paid} name='paid' onChange={onChangeValue} />
            </div>
          </div>
          <div id='partTwo' style={styles.partTwo}>
            <div id='partTwoBox' style={styles.partTwoBox}>
              <div id='description' style={styles.description}>
                <div id='descriptionBox' style={styles.descriptionBox}>
                  <Input
                    placeholder='Descrizione'
                    onChange={onChangeValue}
                    name='description'
                    value={currentRow.description}
                    checkError={
                      !currentRow.description || (currentRow.description && currentRow.description === '')
                    }
                  />
                </div>
                <div id='iconBox' style={styles.iconBox}>
                  <Icon
                    id='iconNote'
                    src={newNote}
                    alt='newNote'
                    style={styles.icon}
                    onClick={(e) => panel.current.toggle(e)}
                  />
                </div>
                <OverlayPanel ref={panel} showCloseIcon dismissable>
                  <Editor
                    style={{ height: 300, width: 400 }}
                    value={currentRow.note}
                    name='note'
                    onTextChange={onChangeEditor}
                  />
                </OverlayPanel>
              </div>
              <div id='project' style={styles.project}>
                <div id='projectBox' style={styles.projectBox} />
              </div>
              <div id='supplier' style={styles.supplier}>
                <div id='supplierBox' style={styles.supplierBox}>
                  <AutoComplete
                    id='autocomplete'
                    style={{
                      ...styles.autocomplete,
                      border: suppliers.find((elem) => elem === currentRow.supplier) ? 'none' : '2px solid red'
                    }}
                    placeholder='Contatti'
                    inputStyle={{ width: '100%', border: 'none' }}
                    completeMethod={completeSupplier}
                    suggestions={supplierSuggestion}
                    name='supplier'
                    onChange={onChangeSupplier}
                    value={currentRow.supplier}
                  />
                </div>
                <div id='iconBox' style={styles.iconBox}>
                  {refreshingSuppliers
                    ? (
                      <Spinner />
                      )
                    : (
                      <Icon
                        id='iconRefresh'
                        src={refresh}
                        alt='refresh'
                        style={styles.icon}
                        onClick={onRefreshingSuppliers}
                      />
                      )}
                </div>
              </div>
            </div>
          </div>
          <div id='partThree' style={styles.partThree}>
            <div id='bankAndPayment' style={{ ...styles.bankAndPayment }}>
              <ListBox
                options={banks}
                name='bank'
                onChange={onChangeValue}
                value={currentRow.bank}
                listStyle={{
                  maxHeight: 150,
                  border:
                    !currentRow.bank || (currentRow.bank && currentRow.bank === '') ? '2px solid red' : 'none'
                }}
              />
              <ListBox
                options={paymentsType}
                name='payment_type'
                onChange={onChangeValue}
                value={currentRow.payment_type}
                listStyle={{
                  maxHeight: 150,
                  border:
                    !currentRow.payment_type || (currentRow.payment_type && currentRow.payment_type === '')
                      ? '2px solid red'
                      : 'none'
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 10 }}>
              <div style={{ display: 'flex' }}>
                <div id='import' style={styles.import}>
                  <Text value='Importo' style={{ marginBottom: 10, marginTop: -20 }} />
                  <Input
                    id='importInput'
                    style={{ ...styles.importInput, height: 30 }}
                    value={currentRow.import}
                    name='import'
                    onChange={onChangeImport}
                    checkError={!currentRow.import || (currentRow.import && currentRow.import === '')}
                    keyfilter='money'
                  />
                </div>

                <div id='import' style={styles.import}>
                  <Text value='Importo senza IVA' style={{ marginBottom: 10, marginTop: -20 }} />
                  <Input
                    id='importNoIva'
                    style={{ ...styles.importInput, height: 30 }}
                    value={currentRow.importNoIva}
                    name='importNoIva'
                    onChange={onChangeImportNoIva}
                    checkError={!currentRow.importNoIva || (currentRow.importNoIva && currentRow.importNoIva === '')}
                    keyfilter='money'
                  />
                </div>
                <div id='import' style={styles.import}>
                  <Text value='Valore % IVA' style={{ marginBottom: 10, marginTop: -20 }} />
                  <Input
                    id='iva'
                    style={{ ...styles.importInput, height: 30 }}
                    value={currentRow.iva}
                    name='iva'
                    onChange={onChangeIva}
                    checkError={!currentRow.iva || (currentRow.iva && currentRow.iva === '')}
                    keyfilter='money'
                  />
                </div>

              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                <div id='paid' style={{ display: 'flex' }}>
                  <div id='paidDiv' style={{ marginRight: 15 }}>
                    Dividi spesa per singolo progetto
                  </div>
                  <InputSwitch checked={currentRow.split} name='split' onChange={onChangeSplitValue} />
                </div>
                {currentRow?.split &&
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <div id='splittedImport' style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                      <Text value='Importo singola spesa' style={{}} />
                      <Input
                        id='splittedImport'
                        disabled
                        style={{ ...styles.importInput, height: 30 }}
                        value={currentRow.splittedImport}
                        name='splittedImport'
                        // checkError={!currentRow.splittedImport || (currentRow.splittedImport && currentRow.splittedImport === '')}
                        keyfilter='money'
                      />
                    </div>
                    <div id='splittedImportNoIva' style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                      <Text value='Importo singola spesa senza IVA' style={{}} />
                      <Input
                        id='splittedImportNoIva'
                        disabled
                        style={{ ...styles.importInput, height: 30 }}
                        name='splittedImportNoIva'
                        value={currentRow.splittedImportNoIva}
                        // checkError={!currentRow.splittedImportNoIva || (currentRow.splittedImportNoIva && currentRow.splittedImportNoIva === '')}
                        keyfilter='money'
                      />
                    </div>
                  </div>}
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <input
                type='file'
                accept='application/pdf'
                ref={upload}
                style={{ display: 'none' }}
                onChange={uploadInv}
              />
              <Button
                style={{ backgroundColor: bgColor, height: 100 }}
                onClick={onClickInvoice}
                onMouseEnter={onMouseEnterInvoice}
                onMouseLeave={onMouseLeaveInvoice}
              >
                <img
                  src={invoiceIcon}
                  alt='Fattura' width='40px' height='50px' title={currentRow.fileName} style={{ padding: '5px' }}
                />
              </Button>

            </div>
          </div>

        </div>
        <div id='right' style={styles.right}>
          <div id='date' style={styles.date}>
            <div id='dateBox' style={styles.dateBox}>
              Data
            </div>
            <div id='calendareDateBox' style={{ ...styles.calendarDateBox }}>
              <Calendar
                id='calendarDate'
                dateFormat='dd/mm/yy'
                inline
                value={currentRow.date}
                name='date'
                locale={IT_STRUCT_CALENDAR}
                onChange={onChangeValue}
                style={{ ...styles.calendarDate }}
              />
            </div>
          </div>
          <div id='sendBox' style={styles.sendBox}>
            {loading
              ? <Spinner />
              : <Button
                  id='sendButton'
                  label='Invia'
                  onClick={() => onSubmit(currentRow)}
                  style={styles.sendButton}
                />}
          </div>
        </div>
      </div>
    </div>

  )
}

export { InsertMulti }
